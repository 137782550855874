import { v4 as uuid } from 'uuid';

// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Avatar9 from 'assets/images/avatar/avatar-9.jpg';

export const DeveloperGeeksData = [
	{
		id: uuid(),
		image: Avatar1,
		content: `"SkillUp kept me up-to-date with practical and applicable courses, giving me the confidence to take on new challenges and please my clients."`,
		name: 'Ronald Richards',
		designation: 'Payments Engineer'
	},
	{
		id: uuid(),
		image: Avatar9,
		content: `"Kanai Academy has created an incredibly seamless experience for education. Thanks to SkillUp I now a Machine Learning Engineer."`,
		name: 'Agatha Muchona',
		designation: 'Machine Learning Engineer'
	}
];
export default DeveloperGeeksData;
