// import node module libraries
import { Col, Row } from 'react-bootstrap';

const HeroContent = () => {
	return (
		<Row>
			<Col lg={{ span: 8, offset: 2 }} md={12} sm={12} className="mb-12">
				{/* caption */}
				<h1 className="display-2 fw-bold mb-3">
					Hi there, we’re <span className="text-primary">Kanai Academy</span>
				</h1>
				{/* para  */}
				<p className="h2 mb-3 ">
					Kanai Academy is an innovative education platform that seeks to equip women and youth in 
					developing countries with digital literacy and skills needed to thrive in the 
					global job market.
				</p>
				<p className="mb-0 h4 text-body lh-lg">
					Our value proposition lies in the fact that we provide affordable and accessible courses 
					that are designed to fit the needs of our target market. We offer a variety of courses in 
					STEAM, digital literacy, manufacturing, entrepreneurship, marketing, and other relevant fields 
					that will help our students gain practical skills that are in demand in today's job market. 
					Our courses are also available in local languages, making them accessible to a wider range of people.
				</p>
			</Col>
		</Row>
	);
};
export default HeroContent;
