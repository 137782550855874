// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Avatar2 from 'assets/images/avatar/avatar-2.jpg';
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import Avatar4 from 'assets/images/avatar/avatar-4.jpg';
import Avatar5 from 'assets/images/avatar/avatar-5.jpg';
import Avatar6 from 'assets/images/avatar/avatar-6.jpg';
import Avatar7 from 'assets/images/avatar/avatar-7.jpg';
import Avatar8 from 'assets/images/avatar/avatar-8.jpg';

export const TestimonialsList = [
	{
		id: 1,
		name: 'Barry Watson',
		designation: 'Web Developer, Zambia',
		image: Avatar1,
		content:
			'SkillUp helped me learn through their practical courses giving me hands-on experience. Thanks to SkillUp, I landed my dream job in machine learning.',
		rating: 5.0,
		color: 'primary'
	},
	{
		id: 2,
		name: 'Linda Shenoy',
		designation: 'Developer and Bootcamp Instructor',
		image: Avatar2,
		content:
			'As a web instructor, I highly recommend SkillUp for its wide range of interactive courses that build real-world skills and confidence in web development.',
		rating: 5.0,
		color: 'info'
	},
	{
		id: 3,
		name: 'Jean Watson',
		designation: 'Engineering Architect',
		image: Avatar3,
		content:
			'SkillUp kept me up-to-date with practical and applicable courses, giving me the confidence to take on new challenges and design innovative solutions for clients.',
		rating: 5.0,
		color: 'danger'
	},

	{
		id: 4,
		name: 'John Deo',
		designation: 'Machine Learning Engineer, Zambia',
		image: Avatar4,
		content:
			'SkillUp helped me learn through their practical courses giving me hands-on experience. Thanks to SkillUp, I landed my dream job in machine learning.',
		rating: 4.5,
		color: 'success'
	},
	{
		id: 5,
		name: 'Rubik Nanda',
		designation: 'Graphic Designer,USA',
		image: Avatar5,
		content:
			'SkillUp\'s courses helped me improve my design skills with comprehensive coverage of tools and techniques, hands-on assignments, and career preparation.',
		rating: 4.5,
		color: 'secondary'
	},
	{
		id: 6,
		name: 'Barry Watson',
		designation: 'Web Developer, UK',
		image: Avatar6,
		content:
			'SkillUp helped me develop my career with comprehensive and easy-to-follow courses, earning certificates that made me stand out in a competitive job market.',
		rating: 4.5,
		color: 'info'
	},
	{
		id: 7,
		name: 'Jean Watson',
		designation: 'Digital Marketer, Malawi',
		image: Avatar7,
		content:
			'SkillUp advanced my digital marketing career with industry-relevant courses and real-world projects, giving me the experience and confidence to succeed.',
		rating: 4.5,
		color: 'warning'
	},
	{
		id: 8,
		name: 'Pezo Rose',
		designation: 'Copywriter, Zambia',
		image: Avatar8,
		content:
			'SkillUp changed the game for me as a copy writer; I learned the latest skills, earned a certificate, and got ready for a career while recommending it to friends.',
		rating: 4.5,
		color: 'dark'
	}
];

export default TestimonialsList;
