// Section : Features
// Style : Three Columns Features Section

// import node module libraries
import { Col, Row, Container } from 'react-bootstrap';

// import MDI icons
import { mdiSchoolOutline, mdiAccountGroup, mdiFinance } from '@mdi/js';

// import custom components
import FeatureTopIconCard from 'components/marketing/common/features/FeatureTopIconCard';

// import sub components
import SectionHeading from './SectionHeading';

const Features3Columns = () => {
	const title = 'Our core values';
	const description = `At the heart of our core lies our unwavering commitment to 
    empower and inspire individuals to shape a brighter future and make a positive impact on the world around them.`;

	const features = [
		{
			id: 1,
			icon: mdiSchoolOutline,
			title: 'Learn and Grow',
			// description: `We value continuous learning and growth, and foster a mindset of exploration and skill development.`
			description: `We strive to create a learning environment that encourages curiosity, exploration, and growth.`
		},
		{
			id: 2,
			icon: mdiAccountGroup,
			title: 'Make Education Accessible',
			// description: `We are committed to breaking down barriers to education and ensuring that learning opportunities are accessible to all.`
			description: `We ensure learners can access online courses worldwide, breaking barriers to education.`
		},
		{
			id: 3,
			icon: mdiFinance,
			title: 'Make Education Affortable',
			description: `We are dedicated to making education affordable and removing financial barriers.`
		}
	];

	return (
		<section className="py-lg-16 py-10">
			<Container>
				<SectionHeading title={title} description={description} />
				<Row>
					{features.map((item, index) => {
						return (
							<Col md={4} sm={12} key={index}>
								<FeatureTopIconCard item={item} />
							</Col>
						);
					})}
				</Row>
			</Container>
		</section>
	);
};

export default Features3Columns;
