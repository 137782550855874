import { v4 as uuid } from 'uuid';

const NavbarDefault = [
	{
		id: uuid(),
		menuitem: 'Browse',
		link: '#',
		children: [
			{
				id: uuid(),
				menuitem: 'Development',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Web Development',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Data Science',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Mobile Development',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Programming Languages',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Game Development',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Database Design',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Software Engineering',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Software Dev Tools',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'No-code Development',
						link: '/courses/category/'
					}
				]
			},
			{
				id: uuid(),
				menuitem: 'Design',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Graphic Design',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Illustrator',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'UX / UI Design',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Figma Design',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Adobe XD',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Sketch',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Icon Design',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Photoshop',
						link: '/courses/category/'
					}
				]
			},
			{
				id: uuid(),
				menuitem: 'Business',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Entrepreneurship',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Communication',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Management',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Sales',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Business Strategy',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Operations',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Human Resources',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Media',
						link: '/courses/category/'
					}
				]
			},
			{
				id: uuid(),
				menuitem: 'Finance & Accounting',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Personal Finance',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Money Management',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Accounting',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Bookkeeping',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Taxes',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Compliance',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Cryptocurrency',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Blockchain',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Economics',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Investing & Trading',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'IT & Software',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'IT Certifications',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Network & Security',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Hardware',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Operating Systems',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Servers',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Marketing',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Digital Marketing',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Social Media Marketing',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Branding',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Marketing Fundamentals',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Paid Advertising',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Content Marketing',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Product Marketing',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Marketing Analytics',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Marketing Automation',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Public Relation',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Office Productivity',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Microsoft',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Apple',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Google',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Personal Development',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Personal Transformation',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Personal Productivity',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Leadership',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Career Development',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Parenting',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Relationships',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Creativity',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Influence',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Stress Management',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Memory Skills',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Study Skills',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Self Esteem & Confidence',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Motivation',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Survivial Skills',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Photography & Video',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Photography',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Photography Tools',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Video Design',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Music',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Instrument',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Music Production',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Music Fundamentals',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Music Technique',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Music Software',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Health & Fitness',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Fitness',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Sports',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Nutrition & Diet',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Mental Health',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Martial Arts & Self Defense',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'First Aid',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Meditation',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Life Style',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Arts & Crafts',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Beauty & Makeup',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Food & Beverage',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Gardening',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Home Improvement',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Pet Care',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Pet Training',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Travel',
						link: '/courses/category/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Teaching',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Engineering',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Math',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Science',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Online Education',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Social Science',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Language Learning',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Teacher Training',
						link: '/courses/category/'
					},
					{
						id: uuid(),
						menuitem: 'Writing',
						link: '/courses/category/'
					},
				]
			},
		]
	},
	// {
	// 	id: uuid(),
	// 	menuitem: 'Path',
	// 	link: '#',
	// },
	{
		id: uuid(),
		menuitem: 'Courses',
		link: '/courses/filter-page/'
	},
	{
		id: uuid(),
		menuitem: 'Paths',
		link: '/courses/paths/'
	},
	{
		id: uuid(),
		menuitem: 'Blog',
		link: '/blog/listing'
	},
	// {
	// 	id: uuid(),
	// 	menuitem: 'Jobs',
	// 	link: '/jobs/'
	// },
	// {
	// 	id: uuid(),
	// 	menuitem: 'Landings',
	// 	link: '#',
	// 	children: [
	// 		{
	// 			id: uuid(),
	// 			header: true,
	// 			header_text: 'LANDINGS'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Home Academy',
	// 			link: '/marketing/landings/home-academy/',
	// 			badge: 'New',
	// 			badgecolor: 'primary'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Courses',
	// 			link: '/marketing/landings/landing-courses/'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Lead Course',
	// 			link: '/marketing/landings/course-lead/'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Request Access',
	// 			link: '/marketing/landings/request-access/'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'SaaS',
	// 			link: '/marketing/landings/landing-sass/'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Job Listing',
	// 			link: '/marketing/landings/landing-job/',
	// 			badge: 'New',
	// 			badgecolor: 'primary'
	// 		}
	// 	]
	// },
	{
		id: uuid(),
		menuitem: 'More',
		link: '#',
		children: [
			// {
			// 	id: uuid(),
			// 	menuitem: 'Courses',
			// 	link: '#',
			// 	children: [
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Course Single',
			// 			link: '/marketing/courses/course-single/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Course Single v2',
			// 			link: '/marketing/courses/course-single2/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Course Resume',
			// 			link: '/marketing/courses/course-resume/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Course Category',
			// 			link: '/marketing/course-category/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Course Checkout',
			// 			link: '/marketing/courses/course-checkout/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Course List/Grid',
			// 			link: '/marketing/courses/course-filter-page/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Add New Course',
			// 			link: '/marketing/instructor/add-new-course/'
			// 		}
			// 	]
			// },

			// {
			// 	id: uuid(),
			// 	menuitem: 'Blog',
			// 	link: '#',
			// 	children: [
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Listing',
			// 			link: '/blog/listing/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Article',
			// 			link: '/blog/article-single/1'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Category',
			// 			link: '/blog/category/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Sidebar',
			// 			link: '/blog/sidebar/'
			// 		}
			// 	]
			// },

			// {
			// 	id: uuid(),
			// 	menuitem: 'Career',
			// 	link: '#',
			// 	children: [
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Overview',
			// 			link: '/career/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Listing',
			// 			link: '/career/listing/'
			// 		},
			// 		{
			// 			id: uuid(),
			// 			menuitem: 'Single',
			// 			link: '/career/single/'
			// 		}
			// 	]
			// },
			{
				id: uuid(),
				menuitem: 'About',
				link: '/about/'
			},
			{
				id: uuid(),
				menuitem: 'Contact',
				link: '/contact/'
			},
			{
				id: uuid(),
				menuitem: 'Pricing',
				link: '/pricing/'
			},
			{
				id: uuid(),
				menuitem: 'Compare Plan',
				link: '/pricing/compare/'
			},
			{
				id: uuid(),
				menuitem: 'Help Center',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Help Center',
						link: '/help-center/'
					},
					{
						id: uuid(),
						menuitem: "FAQ's",
						link: '/help-center/faq/'
					},
					{
						id: uuid(),
						menuitem: 'Guide',
						link: '/help-center/guide/'
					},
					{
						id: uuid(),
						menuitem: 'Guide Single',
						link: '/help-center/guide-single/getting-started/what-is-this-skillup-app'
					},
					{
						id: uuid(),
						menuitem: 'Support',
						link: '/help-center/support/'
					}
				]
			},
			{
				id: uuid(),
				divider: true
			},
			{
				id: uuid(),
				menuitem: 'Job',
				link: '#',
				badge: 'New',
				badgecolor: 'primary',
				children: [
					{
						id: uuid(),
						menuitem: 'Home',
						link: '/jobs/'
					},
					{
						id: uuid(),
						menuitem: 'List',
						link: '/jobs/listing/'
					},
					{
						id: uuid(),
						menuitem: 'Grid',
						link: '/jobs/listing/'
					},
					{
						id: uuid(),
						menuitem: 'Single',
						link: '/jobs/listing/helpdesk-software-engineer-web3crypto/'
					},
					{
						id: uuid(),
						menuitem: 'Company List',
						link: '/jobs/company-list/'
					},
					{
						id: uuid(),
						menuitem: 'Company Single',
						link: '/jobs/company/about/'
					},
					{
						id: uuid(),
						menuitem: 'Company Review',
						link: '/jobs/company-list/'
					},
				]
			},
			{
				id: uuid(),
				menuitem: 'Specialty',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Coming Soon',
						link: '/marketing/specialty/coming-soon/'
					},
					{
						id: uuid(),
						menuitem: 'Error 404',
						link: '/marketing/specialty/404-error/'
					},
					{
						id: uuid(),
						menuitem: 'Maintenance Mode',
						link: '/marketing/specialty/maintenance-mode/'
					},
					{
						id: uuid(),
						menuitem: 'Terms & Conditions',
						link: '/marketing/specialty/terms-and-conditions/'
					}
				]
			},
		]
	},
	{
		id: uuid(),
		menuitem: 'Accounts',
		link: '#',
		children: [
			{
				id: uuid(),
				header: true,
				header_text: 'Accounts'
			},
			{
				id: uuid(),
				menuitem: 'Instructor',
				link: '#',
				children: [
					{
						id: uuid(),
						header: true,
						header_text: 'Instructor',
						description: 'Instructor dashboard for manage courses and earning.'
					},
					{
						id: uuid(),
						divider: true
					},
					{
						id: uuid(),
						menuitem: 'Dashboard',
						link: '/marketing/instructor/dashboard/'
					},
					{
						id: uuid(),
						menuitem: 'Profile',
						link: '/marketing/instructor/instructor-profile/'
					},
					{
						id: uuid(),
						menuitem: 'My Courses',
						link: '/marketing/instructor/instructor-my-courses/'
					},
					{
						id: uuid(),
						menuitem: 'Orders',
						link: '/marketing/instructor/instructor-orders/'
					},
					{
						id: uuid(),
						menuitem: 'Reviews',
						link: '/marketing/instructor/instructor-reviews/'
					},
					{
						id: uuid(),
						menuitem: 'Students',
						link: '/marketing/instructor/instructor-students/'
					},
					{
						id: uuid(),
						menuitem: 'Payouts',
						link: '/marketing/instructor/instructor-payouts/'
					},
					{
						id: uuid(),
						menuitem: 'Earning',
						link: '/marketing/instructor/instructor-earnings/'
					},
					{
						id: uuid(),
						menuitem: 'Quiz',
						link: '#',
						badge: 'New',
						badgecolor: 'primary',
						children: [
							{
								id: uuid(),
								menuitem: 'Quiz',
								link: '/marketing/instructor/quiz/'
							},
							{
								id: uuid(),
								menuitem: 'Single',
								link: '/marketing/instructor/quiz/single/'
							},
							{
								id: uuid(),
								menuitem: 'Result',
								link: '/marketing/instructor/quiz/result/'
							}
						]
					}
				]
			},
			{
				id: uuid(),
				menuitem: 'Students',
				link: '#',
				children: [
					{
						id: uuid(),
						header: true,
						header_text: 'Students',
						description:
							'Students dashboard to manage your courses and subscriptions.'
					},
					{
						id: uuid(),
						divider: true
					},
					{
						id: uuid(),
						menuitem: 'Dashboard',
						link: '/marketing/student/dashboard/'
					},
					{
						id: uuid(),
						menuitem: 'Subscriptions',
						link: '/marketing/student/student-subscriptions/'
					},
					{
						id: uuid(),
						menuitem: 'Payments',
						link: '/marketing/student/student-payment/'
					},
					{
						id: uuid(),
						menuitem: 'Billing Info',
						link: '/marketing/student/student-billing-info/'
					},
					{
						id: uuid(),
						menuitem: 'Invoice',
						link: '/marketing/student/student-invoice/'
					},
					{
						id: uuid(),
						menuitem: 'Invoice Details',
						link: '/marketing/student/student-invoice-details/'
					},
					{
						id: uuid(),
						menuitem: 'Bookmarked',
						link: '/marketing/student/dashboard/'
					},
					{
						id: uuid(),
						menuitem: 'My Path',
						link: '/marketing/student/dashboard/'
					},
					{
						id: uuid(),
						menuitem: 'Quiz',
						link: '#',
						badge: 'New',
						badgecolor: 'primary',
						children: [
							{
								id: uuid(),
								menuitem: 'Quiz',
								link: '/marketing/student/quiz/'
							},
							{
								id: uuid(),
								menuitem: 'Attempt',
								link: '/marketing/student/quiz/attempt/'
							},
							{
								id: uuid(),
								menuitem: 'Start',
								link: '/marketing/student/quiz/start/'
							},
							{
								id: uuid(),
								menuitem: 'Result',
								link: '/marketing/student/quiz/result/'
							}
						]
					}
				]
			},
			{
				id: uuid(),
				menuitem: 'Admin',
				link: '#',
				children: [
					{
						id: uuid(),
						header: true,
						header_text: 'Master Admin',
						description:
							'Master admin dashboard to manage courses, user, site setting, and work with amazing apps.'
					},
					{
						id: uuid(),
						divider: true
					},
					{
						id: uuid(),
						menuitem: 'Go to Dashboard',
						link: '/dashboard/overview/',
						type: 'button'
					}
				]
			},
			{
				id: uuid(),
				divider: true
			},
			{
				id: uuid(),
				menuitem: 'Sign In',
				link: '/authentication/sign-in/'
			},
			{
				id: uuid(),
				menuitem: 'Sign Up',
				link: '/authentication/sign-up/'
			},
			{
				id: uuid(),
				menuitem: 'Forgot Password',
				link: '/authentication/forget-password/'
			},
			{
				id: uuid(),
				menuitem: 'Edit Profile',
				link: '/marketing/student/student-edit-profile/'
			},
			{
				id: uuid(),
				menuitem: 'Security',
				link: '/marketing/student/student-security/'
			},
			{
				id: uuid(),
				menuitem: 'Social Profiles',
				link: '/marketing/student/student-social-profiles/'
			},
			{
				id: uuid(),
				menuitem: 'Notifications',
				link: '/marketing/student/student-notifications/'
			},
			{
				id: uuid(),
				menuitem: 'Privacy Settings',
				link: '/marketing/student/student-profile-privacy/'
			},
			{
				id: uuid(),
				menuitem: 'Delete Profile',
				link: '/marketing/student/student-delete-profile/'
			},
			{
				id: uuid(),
				menuitem: 'Linked Accounts',
				link: '/marketing/student/student-linked-accounts/'
			}
		]
	}
];

export default NavbarDefault;
