// import media files

// import avatar media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Avatar2 from 'assets/images/avatar/avatar-2.jpg';
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import Avatar4 from 'assets/images/avatar/avatar-4.jpg';
import Avatar5 from 'assets/images/avatar/avatar-5.jpg';
import Avatar6 from 'assets/images/avatar/avatar-6.jpg';
import Avatar7 from 'assets/images/avatar/avatar-7.jpg';
import Avatar9 from 'assets/images/avatar/avatar-9.jpg';
import Avatar8 from 'assets/images/avatar/avatar-8.jpg';
import Avatar10 from 'assets/images/avatar/avatar-10.jpg';

// import courses media files
import LaravelCourse from 'assets/images/course/course-laravel.jpg';
import GatsbyCourse from 'assets/images/course/course-gatsby.jpg';
import JavaScriptCourse from 'assets/images/course/course-javascript.jpg';
import NodeCourse from 'assets/images/course/course-node.jpg';
import ReactCourse from 'assets/images/course/course-react.jpg';
import AngularCourse from 'assets/images/course/course-angular.jpg';
import GraphQLCourse from 'assets/images/course/course-graphql.jpg';
import HTMLCourse from 'assets/images/course/course-html.jpg';
import PythonCourse from 'assets/images/course/course-python.jpg';
import CSSCourse from 'assets/images/course/course-css.jpg';
import WordPressCourse from 'assets/images/course/course-wordpress.jpg';

export const AllCoursesData = [
	{
		id: 1,
		category: 'gatsby',
		image: GatsbyCourse,
		title: 'Revolutionize how you build the web',
		date_added: 'Added on 7 July, 2020',
		instructor_name: 'Jenny Wilson',
		instructor_image: Avatar7,
		status: 'Pending',
		level: 'Intermediate',
		duration: '1h 46m',
		price: 850,
		discount: 50,
		rating: 2.0,
		ratingby: 16500,
		recommended: false,
		popular: false,
		trending: true,
		progress: 45
	},
	{
		id: 2,
		category: 'graphql',
		image: GraphQLCourse,
		title: 'GraphQL: introduction to graphQL for beginners',
		date_added: 'Added on 6 July, 2021',
		instructor_name: 'Brooklyn Simmons',
		instructor_image: Avatar6,
		status: 'Pending',
		level: 'Advance',
		duration: '2h 40m',
		price: 600,
		discount: 100,
		rating: 2.5,
		ratingby: 1500,
		recommended: true,
		popular: false,
		trending: false,
		progress: 95
	},
	{
		id: 3,
		category: 'html',
		image: HTMLCourse,
		title: 'HTML Full Course - Build a Website Tutorial',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Miston Wilson',
		instructor_image: Avatar5,
		status: 'Pending',
		level: 'Beginner',
		duration: '3h 16m',
		price: 700,
		discount: 150,
		rating: 3.0,
		ratingby: 1600,
		recommended: false,
		popular: true,
		trending: true,
		progress: 55
	},
	{
		id: 4,
		category: 'javascript',
		image: JavaScriptCourse,
		title: 'A Complete Beginner’s Guide to JavaScript',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Jenny Wilson',
		instructor_image: Avatar1,
		status: 'Live',
		level: 'Advance',
		duration: '4h 10m',
		price: 850,
		discount: 150,
		rating: 3.5,
		ratingby: 7500,
		recommended: true,
		popular: true,
		trending: false,
		progress: 50
	},
	{
		id: 5,
		category: 'nodejs',
		image: NodeCourse,
		title: 'Beginning Node.js, Express & MongoDB Development',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Live',
		level: 'Intermediate',
		duration: '2h 00m',
		price: 950,
		discount: 150,
		rating: 4.0,
		ratingby: 15700,
		recommended: true,
		popular: true,
		trending: true,
		progress: 45
	},
	{
		id: 6,
		category: 'laravel',
		image: LaravelCourse,
		title:
			'Laravel: The Ultimate Beginner’s Guide to Learn Laravel Step by Step',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Live',
		level: 'Beginner',
		duration: '1h 00m',
		price: 220,
		discount: 50,
		rating: 4.5,
		ratingby: 2500,
		recommended: true,
		popular: false,
		trending: true,
		progress: 65
	},
	{
		id: 7,
		category: 'react',
		image: ReactCourse,
		title: 'How to easily create a website with React',
		date_added: 'Added on 4 July, 2021',
		instructor_name: 'April Noms',
		instructor_image: Avatar2,
		status: 'Live',
		level: 'Advance',
		duration: '3h 55m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 1500,
		recommended: true,
		popular: true,
		trending: true,
		progress: 75
	},
	{
		id: 8,
		category: 'angular',
		image: AngularCourse,
		title: 'Angular - the complete guide for beginner',
		date_added: 'Added on 3 July, 2021',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar4,
		status: 'Pending',
		level: 'Intermediate',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 1600,
		recommended: true,
		popular: true,
		trending: true,
		progress: 45
	},
	{
		id: 9,
		category: 'laravel',
		image: LaravelCourse,
		title:
			"Laravel: The Ultimate Beginner's Guide to Learn Laravel Step by Step",
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Live',
		level: 'Beginner',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 11500,
		recommended: true,
		popular: true,
		trending: true,
		progress: 59
	},
	{
		id: 10,
		category: 'nodejs',
		image: NodeCourse,
		title: 'Beginning Node.js, Express & MongoDB Development',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Live',
		level: 'Intermediate',
		duration: '2h 40m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 13500,
		recommended: true,
		popular: true,
		trending: true,
		progress: 95
	},
	{
		id: 11,
		category: 'python',
		image: PythonCourse,
		title: 'The Python Course: build web application',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Ted Hawkins',
		instructor_image: Avatar10,
		status: 'Live',
		level: 'Beginner',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 7800,
		recommended: true,
		popular: true,
		trending: true,
		progress: 45
	},
	{
		id: 12,
		category: 'laravel',
		image: LaravelCourse,
		title:
			'Laravel: The Ultimate Beginner’s Guide to Learn Laravel Step by Step',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Live',
		level: 'Beginner',
		duration: '1h 46m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 3600,
		recommended: true,
		popular: true,
		trending: true,
		progress: 95
	},
	{
		id: 13,
		category: 'react',
		image: ReactCourse,
		title: 'How to easily create a website with React',
		date_added: 'Added on 4 July, 2021',
		instructor_name: 'April Noms',
		instructor_image: Avatar2,
		status: 'Live',
		level: 'Intermediate',
		duration: '2h 40m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 16500,
		recommended: true,
		popular: true,
		trending: true,
		progress: 65
	},
	{
		id: 14,
		category: 'angular',
		image: AngularCourse,
		title: 'Angular - the complete guide for beginner',
		date_added: 'Added on 3 July, 2021',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar4,
		status: 'Pending',
		level: 'Intermediate',
		duration: '6h 00m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 1500,
		recommended: true,
		popular: true,
		trending: true,
		progress: 85
	},
	{
		id: 15,
		category: 'gatsby',
		image: GatsbyCourse,
		title: 'Revolutionize how you build the web',
		date_added: 'Added on 7 July, 2020',
		instructor_name: 'Jenny Wilson',
		instructor_image: Avatar7,
		status: 'Pending',
		level: 'Advance',
		duration: '6h 46m',
		price: 750,
		discount: 150,
		rating: 3.0,
		ratingby: 1653,
		recommended: true,
		popular: true,
		trending: true,
		progress: 45
	},
	{
		id: 16,
		category: 'graphql',
		image: GraphQLCourse,
		title: 'GraphQL: introduction to graphQL for beginners',
		date_added: 'Added on 6 July, 2021',
		instructor_name: 'Brooklyn Simmons',
		instructor_image: Avatar6,
		status: 'Pending',
		level: 'Beginner',
		duration: '5h 46m',
		price: 750,
		discount: 150,
		rating: 5.0,
		ratingby: 16500,
		recommended: true,
		popular: true,
		trending: true,
		progress: 55
	},
	{
		id: 17,
		category: 'angular',
		image: AngularCourse,
		title: 'Angular - the complete guide for beginner',
		date_added: 'Added on 3 July, 2021',
		instructor_name: 'Jenny Wilson',
		instructor_image: Avatar4,
		status: 'Pending',
		level: 'Intermediate',
		duration: '3h 30m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 1800,
		recommended: true,
		popular: true,
		trending: true,
		progress: 88
	},
	{
		id: 18,
		category: 'laravel',
		image: LaravelCourse,
		title:
			'Laravel: The Ultimate Beginner’s Guide to Learn Laravel Step by Step',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Live',
		level: 'Intermediate',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 1200,
		recommended: true,
		popular: true,
		trending: true,
		progress: 45
	},
	{
		id: 19,
		category: 'nodejs',
		image: NodeCourse,
		title: 'Beginning Node.js, Express & MongoDB Development',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Live',
		level: 'Advance',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 3.5,
		ratingby: 16800,
		recommended: true,
		popular: true,
		trending: true,
		progress: 55
	},
	{
		id: 20,
		category: 'javascript',
		image: JavaScriptCourse,
		title: 'Applying JavaScript and using the console.',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Guy Hawkins',
		instructor_image: Avatar10,
		status: 'Live',
		level: 'Beginner',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 2.5,
		ratingby: 19500,
		recommended: true,
		popular: true,
		trending: true,
		progress: 95
	},
	{
		id: 21,
		category: 'javascript',
		image: JavaScriptCourse,
		title: 'Creating a Custom Event in Javascript',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Juanita Bell',
		instructor_image: Avatar10,
		status: 'Live',
		level: 'Beginner',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 9300,
		recommended: false,
		popular: false,
		trending: false,
		progress: 55
	},
	{
		id: 22,
		category: 'javascript',
		image: JavaScriptCourse,
		title: 'Morden JavaScript Beginner Tutorial - Simple',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Ted Hawkins',
		instructor_image: Avatar10,
		status: 'Live',
		level: 'Beginner',
		duration: '2h 46m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 7800,
		recommended: false,
		popular: false,
		trending: false,
		progress: 45
	},
	{
		id: 23,
		category: 'css',
		image: CSSCourse,
		title: 'CSS: ultimate CSS course from beginner to advanced',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Juanita Bell',
		instructor_image: Avatar7,
		status: 'Live',
		level: 'Beginner',
		duration: '1h 30m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 17000,
		recommended: true,
		popular: false,
		trending: false,
		progress: 95
	},
	{
		id: 24,
		category: 'css',
		image: WordPressCourse,
		title: 'Wordpress: complete WordPress theme & plugin development',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Claire Robertson',
		instructor_image: Avatar8,
		status: 'Live',
		level: 'Beginner',
		duration: '1h 30m',
		price: 750,
		discount: 150,
		rating: 4.5,
		ratingby: 17000,
		recommended: true,
		popular: false,
		trending: false,
		progress: 95
	},
	{
		id: 25,
		category: 'javascript',
		image: JavaScriptCourse,
		title: 'JavaScript : A Complete Beginner’s Guide',
		date_added: 'Added on 5 July, 2021',
		instructor_name: 'Guy Robertson',
		instructor_image: Avatar1,
		status: 'Live',
		level: 'Advance',
		duration: '4h 10m',
		price: 850,
		discount: 150,
		rating: 3.5,
		ratingby: 7500,
		recommended: false,
		popular: false,
		trending: false,
		progress: 95
	}
];

export default AllCoursesData;
